import React, { useMemo, useEffect, useState } from "react";
import { LazyLoadImage } from "react-lazy-load-image-component";
import deposit_img from "../../../icon/money-deposit.png";
import phone_img from "../../../icon/phone-home.png";
import { Link, useNavigate } from "react-router-dom";
import { MarketOverview, SingleTicker } from "react-ts-tradingview-widgets";
import trade_img from "../../../icon/trade_ic.png";
import API_URL from "../../../config/api";
import Marquee from "react-fast-marquee";

import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import timezone from "dayjs/plugin/timezone";

import CampaignIcon from "@mui/icons-material/Campaign";

import { useTranslation } from "react-i18next";
import TradingViewWidget from "./TradingViewWidget";
import CoinMarketCapWidget from "./CoinMarketCapWidget";
import CoinList from "../Trade/CoinList";

function Home() {
  const { t } = useTranslation();
  const navigate = useNavigate();
 
  dayjs.extend(utc);
  dayjs.extend(timezone);
  const [notice, setNotice] = useState("");
  useEffect(() => {
    getWebUrl();
  }, []);
  const getWebUrl = async () => {
    await API_URL.get(`api/weburl/getnotice`)
      .then((res) => {
        const allweburl = res.data;
        if (allweburl.length !== 0) {
          const resaboutus = allweburl.find(({ name }) => name === "notice");
          setNotice(resaboutus.nameurl);
        }
        return res.data;
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const changeCoin = (coin, name) => {
    navigate('/login/trade', { state: { coin: coin, name: name,tabvalue:"1" } });

  };


  const coins_list = useMemo(() => {
    const now = dayjs().tz("Asia/Bangkok");
    const day = now.day();
    const hour = now.hour();
    const minute = now.minute();

    // ตรวจสอบว่าตลาด Forex เปิดหรือไม่ (จันทร์ 4:00 น. ถึง เสาร์ 4:00 น.)
    const isForexOpen =
      (day === 1 && (hour > 4 || (hour === 4 && minute >= 0))) ||
      (day >= 2 && day <= 5) ||
      (day === 6 && (hour < 4 || (hour === 4 && minute === 0)));

    const forexSymbols = isForexOpen
      ? [
          { s: "FX:EURUSD", d: "EUR/USD", n: "EUR" },
          { s: "FX:GBPUSD", d: "GBP/USD", n: "GBP" },
          { s: "FX:AUDUSD", d: "AUD/USD", n: "AUD" },
          { s: "FX:USDJPY", d: "USD/JPY", n: "JPY" },
          { s: "FX:USDCAD", d: "USD/CAD", n: "CAD" },
          { s: "OANDA:XAUUSD", d: "Gold Spot/U.S. DOLLAR", n: "XAU" },
        ]
      : [];

    return [
      {
        title: "Bonds",
        symbols: [
          ...forexSymbols,
          {
            s: "BINANCE:BTCUSDT",
            d: "Bitcoin",
            n: "BTCUSDT",
          },
          {
            s: "BINANCE:ETHUSDT",
            d: "Ethereum",
            n: "ETHUSDT",
          },
          {
            s: "BINANCE:SHIBUSDT",
            d: "Shiba Inu",
            n: "SHIBUSDT",
          },
          {
            s: "BINANCE:BNBUSDT",
            d: "Binance Coin",
            n: "BNBUSDT",
          },
          {
            s: "BINANCE:DOGEUSDT",
            d: "Dogecoin",
            n: "DOGEUSDT",
          },
          {
            s: "BINANCE:SOLUSDT",
            d: "Solana",
            n: "SOLUSDT",
          },
          {
            s: "BINANCE:XRPUSDT",
            d: "XRP",
            n: "XRPUSDT",
          },
          {
            s: "BINANCE:ADAUSDT",
            d: "Cardano",
            n: "ADAUSDT",
          },

          {
            s: "BINANCE:DOTUSDT",
            d: "Polkadot",
            n: "DOTUSDT",
          },
          {
            s: "BINANCE:LTCUSDT",
            d: "Litecoin",
            n: "LTCUSDT",
          },
          {
            s: "BINANCE:LINKUSDT",
            d: "Chainlink",
            n: "LINKUSDT",
          },
          // {
          //   s: "BINANCE:LINKUSDT",
          //   d: "Uniswap",
          // },
          {
            s: "BINANCE:AVAXUSDT",
            d: "Avalanche",
            n: "AVAXUSDT",
          },

          // {
          //   s: "BINANCE:MATICUSDT",
          //   d: "Polygon",
          //   n: "MATICUSDT",
          // },
          {
            s: "BINANCE:ATOMUSDT",
            d: "Cosmos",
            n: "ATOMUSDT",
          },
        ],
        originalTitle: "Bonds",
      },
    ];
  }, []);
  return (
    <div className="w-full">
      <div className="pb-2 px-1 lg:px-3">
        <div className="px-1 lg:px-5 py-2 bg-zinc-800 rounded-lg">
          <div className="relative grid grid-cols-1 px-1 lg:px-5  bg-zinc-800 rounded-lg">
            {/* <div className="absolute inset-0"></div> */}
            {/* <TradingViewWidget /> */}
            <CoinMarketCapWidget />

            {/* <SingleTicker
                isTransparent="true"
                colorTheme="dark"
                width="100%"
                autoSize={true}
                symbol="BTCUSDT"
                className="pointer-events-none"
              ></SingleTicker>
              <SingleTicker
                isTransparent="true"
                colorTheme="dark"
                width="100%"
                symbol="ETHUSDT"
                className="pointer-events-none"
              ></SingleTicker>
              <SingleTicker
                isTransparent="true"
                colorTheme="dark"
                width="100%"
                symbol="SHIBUSDT"
                className="pointer-events-none"
              ></SingleTicker>
              <SingleTicker
                isTransparent="true"
                colorTheme="dark"
                width="100%"
                symbol="BNBUSDT"
                className="pointer-events-none"
              ></SingleTicker>
              <SingleTicker
                isTransparent="true"
                colorTheme="dark"
                width="100%"
                symbol="EURUSD"
                className="pointer-events-none"
              ></SingleTicker>
              <SingleTicker
                isTransparent="true"
                colorTheme="dark"
                width="100%"
                symbol="USDCAD"
                className="pointer-events-none"
              ></SingleTicker> */}
          </div>

          <div className="h-0.5 bg-slate-700"></div>
          <div className="flex items-center mt-2">
            <CampaignIcon
              style={{ color: "white" }}
              className="scale-100 lg:scale-125 mr-0.5 lg:mr-1"
            />

            <Marquee speed={30} className="text-white text-sm lg:text-base" >&emsp;&emsp;&emsp;{notice}&emsp;&emsp;&emsp;&emsp;</Marquee>
          </div>
        </div>
      </div>
      <div className="grid grid-cols-3 gap-3 w-full px-2 lg:px-5">
        <Link
          to={`/login/deposit`}
          className="w-full block md:flex justify-center  rounded-md text-center btn-home"
        >
          <LazyLoadImage
            className="h-16 lg:h-24 p-4 mx-auto md:mx-0"
            src={deposit_img}
          />
          <p className="text-white my-auto text-xs md:text-sm pb-3 md:pb-0">
            {t("deposit")}
          </p>
        </Link>
        <button
          onClick={() => window.$chatwoot.toggle("open")}
          className="w-full block md:flex justify-center  rounded-md text-center btn-home"
        >
          <LazyLoadImage
            className="h-16 lg:h-24 p-4 mx-auto md:mx-0"
            src={phone_img}
          />
          <p className="text-white my-auto text-xs md:text-sm pb-3 md:pb-0">
            {t("contact")}
          </p>
        </button>
        <Link
          to={`/login/trade`}
          className="w-full block md:flex justify-center  rounded-md text-center btn-home"
        >
          <LazyLoadImage
            className="h-16 lg:h-24 p-4 mx-auto md:mx-0"
            src={trade_img}
          />
          <p className="text-white my-auto text-xs md:text-sm pb-3 md:pb-0">
            {t("trade")}
          </p>
        </Link>
      </div>
      <div>
        <div className="py-2 px-3">
          <div className="h-screen mt-2 relative">
            <div className="absolute inset-0"></div>
            <div className="tradingview-widget-container-list-main">
        <div className="tradingview-widget-container-list-click">
          <ul>
            {coins_list[0].symbols.map((coin) => (
              <li key={coin.d} onClick={(e) => changeCoin(coin.s, coin.n)}></li>
            ))}
          </ul>
        </div>
        <div className="tradingview-widget-container-list h-full">
          <MarketOverview
            showChart={false}
            width={"100%"}
            colorTheme={"dark"}
            height={"100%"}
            // isTransparent={true}
            tabs={coins_list}
          ></MarketOverview>
        </div>
      </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Home;
