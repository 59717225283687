import React, { useContext, useEffect, useRef, useMemo } from "react";
import { MarketOverview } from "react-ts-tradingview-widgets";
import "./CoinList.css";
import FactCheckIcon from "@mui/icons-material/FactCheck";
import { useTranslation } from "react-i18next";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import timezone from "dayjs/plugin/timezone";

import { TradeContext } from "./Trade";
const CoinList = () => {
  dayjs.extend(utc);
  dayjs.extend(timezone);
  const {
    setSelectedSymbol,
    setSelectCurrency,
    setIsModalCoin,
    setSelectedSymbolname,
    setSelectCurrencyname,
    setSelecttabtrade,
    setTabValue,
  } = useContext(TradeContext);

  const { t } = useTranslation();

  const coins_list = useMemo(() => {
    const now = dayjs().tz("Asia/Bangkok");
    const day = now.day();
    const hour = now.hour();
    const minute = now.minute();

    // ตรวจสอบว่าตลาด Forex เปิดหรือไม่ (จันทร์ 4:00 น. ถึง เสาร์ 4:00 น.)
    const isForexOpen =
      (day === 1 && (hour > 4 || (hour === 4 && minute >= 0))) ||
      (day >= 2 && day <= 5) ||
      (day === 6 && (hour < 4 || (hour === 4 && minute === 0)));

    const forexSymbols = isForexOpen
      ? [
          { s: "FX:EURUSD", d: "EUR/USD", n: "EUR" },
          { s: "FX:GBPUSD", d: "GBP/USD", n: "GBP" },
          { s: "FX:AUDUSD", d: "AUD/USD", n: "AUD" },
          { s: "FX:USDJPY", d: "USD/JPY", n: "JPY" },
          { s: "FX:USDCAD", d: "USD/CAD", n: "CAD" },
          { s: "OANDA:XAUUSD", d: "Gold Spot/U.S. DOLLAR", n: "XAU" },
        ]
      : [];

    return [
      {
        title: "Bonds",
        symbols: [
          ...forexSymbols,
          {
            s: "BINANCE:BTCUSDT",
            d: "Bitcoin",
            n: "BTCUSDT",
          },
          {
            s: "BINANCE:ETHUSDT",
            d: "Ethereum",
            n: "ETHUSDT",
          },
          {
            s: "BINANCE:SHIBUSDT",
            d: "Shiba Inu",
            n: "SHIBUSDT",
          },
          {
            s: "BINANCE:BNBUSDT",
            d: "Binance Coin",
            n: "BNBUSDT",
          },
          {
            s: "BINANCE:DOGEUSDT",
            d: "Dogecoin",
            n: "DOGEUSDT",
          },
          {
            s: "BINANCE:SOLUSDT",
            d: "Solana",
            n: "SOLUSDT",
          },
          {
            s: "BINANCE:XRPUSDT",
            d: "XRP",
            n: "XRPUSDT",
          },
          {
            s: "BINANCE:ADAUSDT",
            d: "Cardano",
            n: "ADAUSDT",
          },

          {
            s: "BINANCE:DOTUSDT",
            d: "Polkadot",
            n: "DOTUSDT",
          },
          {
            s: "BINANCE:LTCUSDT",
            d: "Litecoin",
            n: "LTCUSDT",
          },
          {
            s: "BINANCE:LINKUSDT",
            d: "Chainlink",
            n: "LINKUSDT",
          },
          // {
          //   s: "BINANCE:LINKUSDT",
          //   d: "Uniswap",
          // },
          {
            s: "BINANCE:AVAXUSDT",
            d: "Avalanche",
            n: "AVAXUSDT",
          },

          // {
          //   s: "BINANCE:MATICUSDT",
          //   d: "Polygon",
          //   n: "MATICUSDT",
          // },
          {
            s: "BINANCE:ATOMUSDT",
            d: "Cosmos",
            n: "ATOMUSDT",
          },
        ],
        originalTitle: "Bonds",
      },
    ];
  }, []);

  // const coins_list = useMemo(
  //   () => [
  //     {
  //       title: "Bonds",
  //       symbols: [
  //         { s: "FX:EURUSD", d: "EUR/USD", n: "EUR" },
  //         { s: "FX:GBPUSD", d: "GBP/USD", n: "GBP" },
  //         { s: "FX:AUDUSD", d: "AUD/USD", n: "AUD" },
  //         { s: "FX:USDJPY", d: "USD/JPY", n: "JPY" },
  //         { s: "FX:USDCAD", d: "USD/CAD", n: "CAD" },
  //         {
  //           s: "BINANCE:BTCUSDT",
  //           d: "Bitcoin",
  //           n: "BTCUSDT",
  //         },
  //         {
  //           s: "BINANCE:ETHUSDT",
  //           d: "Ethereum",
  //           n: "ETHUSDT",
  //         },
  //         {
  //           s: "BINANCE:SHIBUSDT",
  //           d: "Shiba Inu",
  //           n: "SHIBUSDT",
  //         },
  //         {
  //           s: "BINANCE:BNBUSDT",
  //           d: "Binance Coin",
  //           n: "BNBUSDT",
  //         },
  //         {
  //           s: "BINANCE:DOGEUSDT",
  //           d: "Dogecoin",
  //           n: "DOGEUSDT",
  //         },
  //         {
  //           s: "BINANCE:SOLUSDT",
  //           d: "Solana",
  //           n: "SOLUSDT",
  //         },
  //         {
  //           s: "BINANCE:XRPUSDT",
  //           d: "XRP",
  //           n: "XRPUSDT",
  //         },
  //         {
  //           s: "BINANCE:ADAUSDT",
  //           d: "Cardano",
  //           n: "ADAUSDT",
  //         },

  //         {
  //           s: "BINANCE:DOTUSDT",
  //           d: "Polkadot",
  //           n: "DOTUSDT",
  //         },
  //         {
  //           s: "BINANCE:LTCUSDT",
  //           d: "Litecoin",
  //           n: "LTCUSDT",
  //         },
  //         {
  //           s: "BINANCE:LINKUSDT",
  //           d: "Chainlink",
  //           n: "LINKUSDT",
  //         },
  //         // {
  //         //   s: "BINANCE:LINKUSDT",
  //         //   d: "Uniswap",
  //         // },
  //         {
  //           s: "BINANCE:AVAXUSDT",
  //           d: "Avalanche",
  //           n: "AVAXUSDT",
  //         },

  //         {
  //           s: "BINANCE:MATICUSDT",
  //           d: "Polygon",
  //           n: "MATICUSDT",
  //         },
  //         {
  //           s: "BINANCE:ATOMUSDT",
  //           d: "Cosmos",
  //           n: "ATOMUSDT",
  //         },
  //         // {
  //         //   s: "TVC:GOLD",
  //         //   d: "Gold Spot (XAUUSDT)",
  //         // },
  //       ],
  //       originalTitle: "Bonds",
  //     },
  //   ],
  //   []
  // );

  const changeCoin = (coin, name) => {
    setSelectedSymbol(coin);
    setSelectedSymbolname(name);
    setIsModalCoin(false);
    setSelectCurrency("selectcurrency", coin.toLowerCase(), {
      path: `/`,
    });
    setSelectCurrencyname("selectcurrencyname", name.toLowerCase(), {
      path: `/`,
    });
    setTabValue("1");
    setSelecttabtrade("selecttabtrade", "1", {
      path: `/`,
    });
  };

  return (
    <div className="w-full h-screen overflow-scroll coinlist_overflow">
      <div className="flex  items-center text-white my-2">
        <FactCheckIcon className="text-left mr-2" />
        <p className="text-xs lg:text-sm">{t("coin_list")}</p>
      </div>
      <div className="my-1 h-0.5 bg-zinc-500"></div>
      <div className="tradingview-widget-container-list-main">
        <div className="tradingview-widget-container-list-click">
          <ul>
            {coins_list[0].symbols.map((coin) => (
              <li key={coin.d} onClick={(e) => changeCoin(coin.s, coin.n)}></li>
            ))}
          </ul>
        </div>
        <div className="tradingview-widget-container-list h-full">
          <MarketOverview
            showChart={false}
            width={"100%"}
            colorTheme={"dark"}
            height={"100%"}
            // isTransparent={true}
            tabs={coins_list}
          ></MarketOverview>
        </div>
      </div>
    </div>
  );
};

export default CoinList;
