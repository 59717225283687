import React, { useState, useEffect } from "react";

import TabLogin from "./Mainsite/TabLogin";
import login_bg from "../../image/login_bg.webp";

import { HomeIcon } from "@heroicons/react/24/solid";

import { useNavigate, useParams } from "react-router-dom";
import mobile_cover from "../../image/mobile_login_cover.jpg";

import logoWeb from "../../image/Bitcoin-SV-logo-white.png";
import LanguageSelector from "../language-selector";
import ChatwootComponent from "./ChatwootWidget";
import Logoweb from "../../Function/Logoweb";
import Nameweb from "../../Function/Nameweb";

function Login_user() {
  let { id } = useParams();
  const navigate = useNavigate();

  const [openTab, setOpenTab] = useState(Number(id) || 2);
  const [isOn, setIsOn] = useState(true);

  const toHome = () => {
    navigate("/");
  };

  const toggleSwitch = () => {
    setIsOn(!isOn);
  };

  return (
    <>
      <div>
        {/* nav style */}
        <div className="w-full p-1 h-20 fixed z-50 top-0 main-bg-color shadow-lg overflow-hidden bg-slate-800 border-b-4 border-slate-900 text-white">
          <div className="grid grid-cols-3 justify-between items-center my-2 md:m-2  mx-auto">
            <div className="flex  justify-start">
              <button
                onClick={toHome}
                className="bg-blue-500 hover:bg-blue-600 text-white font-bold p-2 rounded-lg transition duration-300"
              >
                <div className="border-12 border-white p-2">
                  <HomeIcon className="h-4 w-4" />
                </div>
              </button>
            </div>
            <div className="flex flex-col lg:flex-row justify-center mx-auto">
              <div className="my-auto  text-center  h-7 lg:h-11 mx-auto object-contain ">
                <Logoweb />
              </div>
              <div className="text-white my-auto truncate w-32 lg:w-full text-center">
                <Nameweb />
              </div>
            </div>
            <div className="w-full  flex justify-end">
              <LanguageSelector />
            </div>
          </div>
        </div>

        <div className="w-full bg-zinc-900 h-screen">
          <div className="w-full container mx-auto mt-20 p-2 md:p-20 ">
            <div className="w-full flex justify-center mx-auto  rounded-xl">
              <div
                className="hidden lg:flex w-1/2 justify-center bg-cover bg-no-repeat rounded-xl "
                style={{ backgroundImage: `url(${login_bg})` }}
              ></div>
              <div
                className="w-full lg:w-1/2 block justify-center p-4 md:p-14"
                style={{ backgroundImage: `url(${mobile_cover})` }}
              >
                <TabLogin openTab={openTab} setOpenTab={setOpenTab} />
              </div>
            </div>
          </div>
          <ChatwootComponent />
        </div>
      </div>
    </>
  );
}

export default Login_user;
